import React from 'react';

import { graphql, Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';

import Hero from '../components/hero.jsx';
import Button from '../components/button.jsx';
import Layout from '../components/layout.jsx';
import Section from '../components/section.jsx';
import TitleBlock from '../components/title-block.jsx';
import Testimonials from '../components/testimonials.jsx';
import FirstMeeting from '../components/first-meeting.jsx';
import ChevronDownIcon from '../icons/outline/chevron-down.jsx';
import Title from '../components/title.jsx';
import SubTitle from '../components/sub-title.jsx';
import Fade from '../components/fade.jsx';

export default function Home(props) {
	let { data } = props;
	let { sanityHomePage } = data;
	let { title, benefits, sectors, solutions, seo } = sanityHomePage;

	let renderSolutions = [];
	for (let solution of solutions?.values || []) {
		let { title, icon } = solution.solution;
		renderSolutions.push(
			<div key={solution._key} className="grid grid-rows-[max-content,1fr,max-content] items-x-center lg:contents">
				<div className="relative z-20 w-40 h-40">
					<Fade scale={Fade.scale.large} opacity="0.10" />
					<img src={icon?.asset?.url} className="w-full h-full" />
				</div>
				<span className="max-w-xs text-2xl text-center lg:mt-2">{title}</span>
				<span className="mt-4">
					<Button as="link" to={`./solutions#${title}`}>
						Discover
					</Button>
				</span>
			</div>,
		);
	}

	let renderBenefits = [];
	for (let benefit of benefits?.values || []) {
		let renderLink;
		if (benefit.horizontal) {
			let name = benefit.horizontal.name.toLowerCase().replace(/\s/g, '-');
			renderLink = (
				<span className="self-start mt-4 lg:mt-0 lg:self-x-start">
					<Button as="link" to={`./benefits?type=${name}`} ariaLabel={benefit.horizontal.name}>
						{benefit.ctaText ?? 'More'}
					</Button>
				</span>
			);
		} else {
			renderLink = <div></div>;
		}

		renderBenefits.push(
			<div key={benefit._key} className="flex flex-col lg:contents">
				<div className="grid grid-cols-1 grid-rows-1">
					<img src={benefit.image?.asset?.url} alt={benefit.title} className="col-start-1 row-start-1 w-96 h-96" />
				</div>

				<div className="max-w-sm">
					<h2 className="pb-3 mb-2 text-3xl font-semibold whitespace-pre-line border-b-2 2xl:text-4xl text-primary border-primary">
						{benefit.title}
					</h2>
					<p className="block mt-6">{benefit.description}</p>
				</div>

				{renderLink}
			</div>,
		);
	}

	let renderSectors = [];
	for (let sector of sectors?.values || []) {
		let renderMore;
		if (sector.vertical) {
			let name = sector.vertical.name.toLowerCase().replace(/\s/g, '-');
			renderMore = (
				<Link
					to={`./industries?type=${name}`}
					className="inline-flex items-center mt-1 group text-primary self-x-start"
					aria-label={sector.vertical.name}
				>
					More
					<svg
						className="w-8 h-8 ml-0.5 transition duration-200 transform opacity-0 group-hover:opacity-100 translate group-hover:translate-x-2"
						fill="none"
						viewBox="0 0 24 24"
						stroke="currentColor"
					>
						<path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 8l4 4m0 0l-4 4m4-4H3" />
					</svg>
				</Link>
			);
		}

		renderSectors.push(
			<div key={sector._key}>
				<div className="grid grid-cols-1 grid-rows-1">
					<img src={sector.image?.asset?.url} alt={sector.title} className="col-start-1 row-start-1 w-80 h-80" />
				</div>
				<div className="max-w-sm mt-8">
					<h2 className="pb-2 mb-2 text-2xl font-medium border-b-2 text-primary border-primary">{sector.title}</h2>
					<p>{sector.description}</p>
					{renderMore}
				</div>
			</div>,
		);
	}

	return (
		<Layout seo={seo}>
			<Hero>
				<div className="grid gap-12 xl:grid-cols-2">
					<div className="grid grid-rows-2 gap-8 xl:max-w-3xl row-1-fr row-2-max">
						<h1 className="text-4xl md:text-5xl 2xl:text-6xl self-y-center">
							<TitleBlock title={title} />
						</h1>

						<Link
							to="#solutions"
							className="flex items-center justify-center mt-12 xl:justify-start xl:mt-0 xl:w-max text-primary"
						>
							<span className="text-sm uppercase md:text-base 2xl:text-lg">Take off</span>
							<ChevronDownIcon className="w-6 h-6 ml-2 transition-transform duration-300 transform text-primary" />
						</Link>
					</div>

					<div className="w-full mt-8 transition-transform duration-500 ease-out transform xl:mt-0 hover:-translate-x-12 hover:translate-y-12 md:mt-12 xl:h-full xl:w-auto xl:max-w-none">
						<StaticImage src="../images/ship.svg" placeholder="none" alt="Spaceship" />
					</div>
				</div>
			</Hero>

			<Section id="solutions">
				<Title>{solutions.title}</Title>
				<SubTitle>{solutions.subtitle}</SubTitle>

				<div className="grid gap-12 lg:gap-4 mt-16 grid-rows-[max-content,1fr,max-content] lg:grid-flow-col items-x-center lg:grid-cols-3">
					{renderSolutions}
				</div>
			</Section>

			<Section>
				<Title>{sectors.title}</Title>
				<SubTitle>{sectors.subtitle}</SubTitle>

				<div className="flex flex-wrap justify-center mt-12 gap-y-16 xl:gap-x-16 2xl:gap-x-24">{renderSectors}</div>
			</Section>

			<Testimonials />

			<Section id="benefits">
				<Title>{benefits.title}</Title>
				<h2 className="uppercase text-tertiary">{benefits.subtitle}</h2>

				<div className="grid mt-12 text-white items-x-center lg:items-x-stretch lg:grid-cols-3 sm:grid-cols-2 lg:grid-flow-col lg:grid-rows-[auto,auto,max-content] gap-x-24 gap-y-12 items-y-start">
					{renderBenefits}
				</div>
			</Section>

			<FirstMeeting />
		</Layout>
	);
}

export const query = graphql`
	query($language: String) {
		sanityHomePage(lang: { eq: $language }) {
			title {
				children {
					_key
					text
					marks
				}
			}
			solutions {
				title
				subtitle
				values {
					_key
					solution {
						_id
						title
						_rawDescription(resolveReferences: { maxDepth: 5 })
						icon {
							asset {
								url
							}
						}
					}
				}
			}
			benefits {
				title
				values {
					_key
					title
					description
					horizontal {
						name
					}
					image {
						asset {
							url
						}
					}
					ctaText
				}
			}
			sectors {
				title
				subtitle
				values {
					_key
					title
					description
					vertical {
						name
					}
					image {
						asset {
							url
						}
					}
				}
			}
			seo {
				description
				image {
					asset {
						url
					}
				}
			}
		}
	}
`;
