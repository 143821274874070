import React from 'react';

export default function ChevronDownIcon(props) {
	let { className } = props;
	return (
		<svg className={className} fill="none" viewBox="0 0 24 24" stroke="currentColor">
			<path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1} d="M19 9l-7 7-7-7" />
		</svg>
	);
}
